import React, { useState } from "react";
import axios from "axios";

import "./GoalReached.scss";
import Button from "../../shared/components/button/Button";

export default function GoalReached({
  score,
  character,
  client = "Mahaska Chamber",
}: {
  score: number;
  character: any;
  client?: string;
}) {
  const [saved, setSaved] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>("");

  const API_PATH = "https://www.mahaskachamber.org/shared/winner-submit.php";

  function handleFormSubmit(e: any) {
    e.preventDefault();
    const form = {
      email: emailAddress,
      score,
      client,
    };

    axios({
      method: "post",
      url: API_PATH,
      headers: { "content-type": "application/json" },
      data: form,
    })
      .then((result) => {
        console.log("save result", result);
        setSaved(true);
      })
      .catch((error) => console.error("save error", error));
  }

  return (
    <div className="goal-reached">
      <div className="goal-reached__pic">
        <img src={character} alt="" />
      </div>
      <div className="goal-reached__title">
        Congratulations! You found all the candy!
      </div>

      {!saved && (
        <div>
          <div className="goal-reached__subtitle">
            Enter your email address to submit your score:
          </div>
          <form className="goal-reached__form">
            <input
              name="goal-reached-email"
              type="email"
              required
              placeholder="Email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />

            <input
              name="nl-client"
              type="text"
              required
              hidden
              readOnly
              value={client}
            />
            <input
              name="goal-reached-score"
              type="number"
              required
              hidden
              readOnly
              value={score}
            />
            <Button type="submit" onClick={(e: any) => handleFormSubmit(e)}>
              Submit
            </Button>
          </form>
        </div>
      )}

      {!!saved && <h1>Thanks for playing!</h1>}
    </div>
  );
}
