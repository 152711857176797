import classnames from "classnames";
import React, { useState } from "react";

import { CandyPosition } from "../../../shared/models/candy-position.model";

import "./CandyGrid.scss";

export default function CandyGrid({
  candies,
  candyHotspots,
  candyBucket,
  candyBucketPosition,
  setCandyBucket,
}: {
  candies: any[];
  candyHotspots: any[];
  candyBucket: any;
  candyBucketPosition: CandyPosition;
  setCandyBucket: any;
}) {
  const [showHelp, setShowHelp] = useState<boolean>(false);

  const helpTimeoutInSeconds = 40;

  setTimeout(() => {
    setShowHelp(true);
  }, helpTimeoutInSeconds * 1000);

  const onClickCandy = (candy: any) => {
    const cb = [...candyBucket, candy];
    setCandyBucket(cb);
  };
  return (
    <div className="CandyGrid">
      {candies.map((c, i) => {
        const isCollected: boolean = !!candyBucket.find(
          (candy: any) => candy === c
        );
        const candyHotspotPosition = candyHotspots?.[i] || [];

        const candyStyle = isCollected
          ? { ...candyBucketPosition }
          : { ...candyHotspotPosition };

        return (
          <div
            onClick={() => onClickCandy(c)}
            key={i}
            className={classnames([
              "CandySpot",
              {
                "CandySpot--secondary": showHelp,
              },
              {
                "CandySpot--collected": isCollected,
              },
            ])}
            style={candyStyle}
          >
            <img src={c} alt="" />
          </div>
        );
      })}
    </div>
  );
}
