import bat from "../../../assets/halloween-kids/bat.png";
import cat from "../../../assets/halloween-kids/cat.png";
import monster from "../../../assets/halloween-kids/monster.png";
import mummy from "../../../assets/halloween-kids/mummy.png";
import potter from "../../../assets/halloween-kids/potter.png";
import princess from "../../../assets/halloween-kids/princess.png";
import pumpkin from "../../../assets/halloween-kids/pumpkin.png";
import skull from "../../../assets/halloween-kids/skull.png";
import vampire from "../../../assets/halloween-kids/vampire.png";
import witch from "../../../assets/halloween-kids/witch.png";

export const characters: any[] = [
  bat,
  cat,
  monster,
  mummy,
  potter,
  princess,
  pumpkin,
  skull,
  vampire,
  witch,
];
