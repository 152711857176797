import React from "react";
import brandingLogo from "../../assets/logo/mahaska.png";

export const Branding = ({ intro }: { intro: boolean }) => (
  <div
    className="Branding"
    style={{ justifyContent: intro ? "center" : "space-between" }}
  >
    <img src={brandingLogo} />
    {intro && <div className="Branding__welcome-msg">Click or Treat!</div>}
  </div>
);
