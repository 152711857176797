import React from "react";
import "./CharacterSelect.scss";

export default function CharacterSelect({
  characters,
  onSelectCharacter,
}: {
  characters: any[];
  onSelectCharacter: (character: any) => any;
}) {
  const gooSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#3fab43"
        fillOpacity="1"
        d="M0,160L6.2,149.3C12.3,139,25,117,37,112C49.2,107,62,117,74,133.3C86.2,149,98,171,111,186.7C123.1,203,135,213,148,197.3C160,181,172,139,185,106.7C196.9,75,209,53,222,69.3C233.8,85,246,139,258,138.7C270.8,139,283,85,295,74.7C307.7,64,320,96,332,128C344.6,160,357,192,369,192C381.5,192,394,160,406,160C418.5,160,431,192,443,186.7C455.4,181,468,139,480,106.7C492.3,75,505,53,517,53.3C529.2,53,542,75,554,112C566.2,149,578,203,591,234.7C603.1,267,615,277,628,250.7C640,224,652,160,665,122.7C676.9,85,689,75,702,64C713.8,53,726,43,738,42.7C750.8,43,763,53,775,80C787.7,107,800,149,812,186.7C824.6,224,837,256,849,240C861.5,224,874,160,886,165.3C898.5,171,911,245,923,277.3C935.4,309,948,299,960,272C972.3,245,985,203,997,197.3C1009.2,192,1022,224,1034,213.3C1046.2,203,1058,149,1071,149.3C1083.1,149,1095,203,1108,213.3C1120,224,1132,192,1145,192C1156.9,192,1169,224,1182,224C1193.8,224,1206,192,1218,170.7C1230.8,149,1243,139,1255,154.7C1267.7,171,1280,213,1292,229.3C1304.6,245,1317,235,1329,197.3C1341.5,160,1354,96,1366,74.7C1378.5,53,1391,75,1403,96C1415.4,117,1428,139,1434,149.3L1440,160L1440,0L1433.8,0C1427.7,0,1415,0,1403,0C1390.8,0,1378,0,1366,0C1353.8,0,1342,0,1329,0C1316.9,0,1305,0,1292,0C1280,0,1268,0,1255,0C1243.1,0,1231,0,1218,0C1206.2,0,1194,0,1182,0C1169.2,0,1157,0,1145,0C1132.3,0,1120,0,1108,0C1095.4,0,1083,0,1071,0C1058.5,0,1046,0,1034,0C1021.5,0,1009,0,997,0C984.6,0,972,0,960,0C947.7,0,935,0,923,0C910.8,0,898,0,886,0C873.8,0,862,0,849,0C836.9,0,825,0,812,0C800,0,788,0,775,0C763.1,0,751,0,738,0C726.2,0,714,0,702,0C689.2,0,677,0,665,0C652.3,0,640,0,628,0C615.4,0,603,0,591,0C578.5,0,566,0,554,0C541.5,0,529,0,517,0C504.6,0,492,0,480,0C467.7,0,455,0,443,0C430.8,0,418,0,406,0C393.8,0,382,0,369,0C356.9,0,345,0,332,0C320,0,308,0,295,0C283.1,0,271,0,258,0C246.2,0,234,0,222,0C209.2,0,197,0,185,0C172.3,0,160,0,148,0C135.4,0,123,0,111,0C98.5,0,86,0,74,0C61.5,0,49,0,37,0C24.6,0,12,0,6,0L0,0Z"
      ></path>
    </svg>
  );
  return (
    <div className="character-select">
      <div className="Goo">
        {gooSvg}
        {gooSvg}
      </div>
      <div className="character-select-title">Select a Character</div>
      <div className="characters">
        {characters.map((c, i) => (
          <div key={i} className="character">
            <img
              src={c}
              alt={"Character"}
              onClick={() => onSelectCharacter(c)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
