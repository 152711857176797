import bat1 from "../../../assets/halloween-candy/bat 1 200x200.png";
import cakePop1 from "../../../assets/halloween-candy/Cake pop 1 200x600.png";
import cakePop2 from "../../../assets/halloween-candy/Cake Pop 2 200x600.png";
import cakePop3 from "../../../assets/halloween-candy/cake pop 3 200x600.png";
import cake600 from "../../../assets/halloween-candy/Cake 600x600.png";
import candyCane1 from "../../../assets/halloween-candy/candy cane 1 200x600.png";
import candyCane2 from "../../../assets/halloween-candy/candy cane 2 200x600.png";
import candyCorn1 from "../../../assets/halloween-candy/candy corn 1 200x200.png";
import candyCorn2 from "../../../assets/halloween-candy/candy corn 2 200x200.png";
import candy1 from "../../../assets/halloween-candy/candy 1 200x200.png";
import candy2 from "../../../assets/halloween-candy/candy 2 200x200.png";
import caramelApple1 from "../../../assets/halloween-candy/caramel apple 1 600x600.png";
import caramelApple2 from "../../../assets/halloween-candy/caramel apple 2 600x600.png";
import chocolate1 from "../../../assets/halloween-candy/chocolate 1 200x200.png";
import chocolate2 from "../../../assets/halloween-candy/chocolate 2 200x200.png";

import { CandyPosition } from "../../../shared/models/candy-position.model";

export const CANDIES: any[] = [
  bat1,
  cakePop1,
  cakePop2,
  cakePop3,
  cake600,
  candyCane1,
  candyCane2,
  candyCorn1,
  candyCorn2,
  candy1,
  candy2,
  caramelApple1,
  caramelApple2,
  chocolate1,
  chocolate2,
];

export const CANDY_HOTSPOTS: CandyPosition[] = [
  { bottom: "450px", left: "380px" },
  { bottom: "120px", left: "680px" },
  { bottom: "280px", left: "1000px" },
  { bottom: "15px", left: "1700px" },
  { bottom: "460px", left: "620px" },
  { bottom: "425px", left: "1550px" },
  { bottom: "100px", left: "1065px" },
  { bottom: "500px", left: "870px" },
  { bottom: "300px", left: "1400px" },
  { bottom: "125px", left: "1350px" },
  { bottom: "100px", left: "855px" },
  { bottom: "170px", left: "1540px" },
  { bottom: "615px", left: "1550px" },
  { bottom: "530px", left: "100px" },
  { bottom: "520px", left: "1140px" },
];
