import React from "react";
import "./Button.scss";

export default function Button(props: any) {
  return (
    <button
      type={props.type || "button"}
      onClick={props.onClick}
      className="Button"
    >
      {props.children}
    </button>
  );
}
