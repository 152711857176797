import React, { useEffect, useState } from "react";

import "./App.scss";
import bg from "./assets/Artboard 2.png";

import { characters } from "./features/character-select/configuration/characters-data";
import SelectedCharacter from "./shared/components/selected-character/SelectedCharacter";
import CandyGrid from "./features/candy-finding/candy-grid/CandyGrid";
import CandyBucket from "./features/candy-finding/candy-bucket/CandyBucket";
import CharacterSelect from "./features/character-select/CharacterSelect";
import GoalReached from "./features/goal-reached/GoalReached";
import { Branding } from "./features/branding/Branding";
import Button from "./shared/components/button/Button";

import {
  CANDIES,
  CANDY_HOTSPOTS,
} from "./features/candy-finding/configuration/candy-data";

function shuffle(array: any[]) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function App() {
  useEffect(() => {
    // @ts-ignore
    if (!window["cotBgPreloaded"]) {
      const img = new Image();
      img.style.display = "none";
      img.src = bg;

      // @ts-ignore
      window["cotBgPreloaded"] = true;
    }
  });
  const [selectedCharacter, setSelectedCharacter] = useState();
  const [gameCandies, setGameCandies] = useState<any[]>();
  const [gameHotspots, setGameHotspots] = useState<any[]>();
  const [candyBucket, setCandyBucket] = useState<Array<any>>([]);
  const [candyGoalReached, setCandyGoalReached] = useState<boolean>(false);
  const [instructionsDismissed, setInstructionsDismissed] = useState<boolean>(
    false
  );

  const candyGoal = 10;
  const bucketPosition = { bottom: "32px", left: "96px" };

  const onSelectCharacter = (character: any) => {
    setSelectedCharacter(character);
  };

  const onCandyGoalReached = () => {
    setTimeout(() => {
      setCandyGoalReached(true);
    }, 1000);
  };

  const onDismissInstructions = () => {
    setInstructionsDismissed(true);
  };

  if (!gameCandies) {
    let candiesForGame = CANDIES.map((c: any) => c);
    shuffle(candiesForGame);
    setGameCandies(candiesForGame.slice(0, candyGoal));
  }

  if (!gameHotspots) {
    let hotspotsForGame = CANDY_HOTSPOTS.map((h: any) => h);
    shuffle(hotspotsForGame);
    setGameHotspots(hotspotsForGame.slice(0, candyGoal));
  }

  return (
    <div className="App">
      {!!selectedCharacter && !instructionsDismissed && (
        <div className="Instructions">
          <h3>Instructions</h3>
          <p>*For the best experience, play on a desktop or laptop computer.</p>
          <p>
            Hover your mouse around the spooky room to uncover 10 hidden
            Halloween candies. When you find one, click it to put it in your
            bucket!
          </p>
          <p>You may need to scroll around to see all of the room.</p>
          <Button onClick={onDismissInstructions}>OK</Button>
        </div>
      )}

      <Branding intro={!selectedCharacter} />
      {instructionsDismissed ? (
        candyGoalReached ? (
          <GoalReached
            score={candyBucket.length}
            character={selectedCharacter}
          />
        ) : (
          <div className="main-game">
            <img className="bg" src={bg} alt="" />

            <SelectedCharacter character={selectedCharacter} />
            <CandyGrid
              candies={gameCandies || []}
              candyHotspots={gameHotspots || []}
              candyBucket={candyBucket}
              candyBucketPosition={bucketPosition}
              setCandyBucket={setCandyBucket}
            />
            <CandyBucket
              candyBucket={candyBucket}
              candyGoal={candyGoal}
              position={bucketPosition}
              onCandyGoalReached={onCandyGoalReached}
            />
          </div>
        )
      ) : (
        <CharacterSelect
          characters={characters}
          onSelectCharacter={onSelectCharacter}
        />
      )}
    </div>
  );
}

export default App;
