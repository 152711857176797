import React from "react";
import "./SelectedCharacter.scss";

export default function SelectedCharacter({ character }: { character: any }) {
  return (
    <div className="SelectedCharacter">
      <img src={character} alt="" />
    </div>
  );
}
