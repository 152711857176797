import React from "react";

import "./CandyBucket.scss";

export default function CandyBucket({
  candyBucket,
  candyGoal,
  position,
  onCandyGoalReached,
}: {
  candyBucket: any;
  candyGoal: number;
  position: any;
  onCandyGoalReached: any;
}) {
  if (candyBucket.length === candyGoal) {
    onCandyGoalReached();
  }

  return (
    <div className="CandyBucket" style={{ ...position }}>
      <div className="CandyBucket__count">
        {candyBucket.length}
        <span className="CandyBucket__goal">
          <span className="CandyBucket__goal-slash">/</span>
          {candyGoal}
        </span>
      </div>
    </div>
  );
}
